import clsx from 'clsx';
import { ComponentProps } from 'react';
import {
    Popover as BasePopover,
    PopoverProps as BasePopoverProps,
    Button,
    Dialog,
} from 'react-aria-components';
import { MdArrowDropDown } from 'react-icons/md';

interface Props extends Omit<BasePopoverProps, 'children'> {
    children: React.ReactNode;
}

export default function Dropdown({ children, ...props }: Props) {
    return (
        <BasePopover {...props}>
            <Dialog className="flex flex-col gap-2 rounded-lg border border-black bg-base-100 p-2 shadow-lg outline-none">
                {children}
            </Dialog>
        </BasePopover>
    );
}

interface DropdownTriggerProps
    extends Omit<ComponentProps<typeof Button>, 'children'> {
    children?: React.ReactNode;
    asChild?: boolean;
}

export function DropdownTrigger({ asChild, ...props }: DropdownTriggerProps) {
    if (asChild) {
        return <Button>{props.children}</Button>;
    }

    return (
        <Button
            {...props}
            className={clsx(
                'flex items-center justify-between gap-2 rounded-md border border-black px-4 py-2.5 dark:border-white',
                props.className,
            )}
        >
            {props.children}

            <MdArrowDropDown aria-hidden="true" />
        </Button>
    );
}

interface DropdownContentProps {
    children: React.ReactNode;
    className?: string;
}

export function DropdownContent({ children, className }: DropdownContentProps) {
    return (
        <div
            className={clsx(
                'flex w-full rounded-lg p-1 hover:cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-500',
                className,
            )}
        >
            {children}
        </div>
    );
}
