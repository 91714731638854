'use client';
import { useTranslation } from '@/app/i18n/client';
import LanguageSelector from '@/components/language-selector';
import ThemeSwitch from '@/components/theme-switch';
import clsx from 'clsx';
import { useTheme } from 'next-themes';
import Image from 'next/image';
import { PropsWithChildren, Suspense } from 'react';
import { Link, Separator } from 'react-aria-components';
import {
    MdFileCopy,
    MdLock,
    MdOutlineQuestionMark,
    MdSupport,
} from 'react-icons/md';
import styles from './layout.module.css';

interface Props extends PropsWithChildren {
    params: { lng: string };
}

export default function PublicLayout({ children, params: { lng } }: Props) {
    const { t } = useTranslation(lng);

    const theme = useTheme();

    return (
        <div
            className={clsx(
                'flex h-max min-h-full w-full flex-col justify-between lg:justify-start',
                styles.container,
            )}
        >
            <div className="hidden w-full items-start justify-between p-2 px-4 lg:flex">
                <Link href={`/${lng}`}>
                    <Image
                        alt="Midas Trackr logo"
                        src={
                            theme.resolvedTheme === 'dark'
                                ? '/logo-white.svg'
                                : '/logo.svg'
                        }
                        width={120 * 1.5}
                        height={24 * 1.5}
                    />
                </Link>

                <div className="flex gap-2">
                    <ThemeSwitch iconClassName="text-[1.5rem]" />

                    <LanguageSelector
                        className="hidden lg:block"
                        currentLanguage={lng as 'pt-BR' | 'en'}
                    />
                </div>
            </div>

            <div className="flex flex-1 flex-col items-center justify-center gap-y-8 p-2 px-4 lg:justify-start lg:p-0 lg:px-4">
                <Link href={`/${lng}/login`} className="block lg:hidden">
                    <Image
                        alt="Midas Trackr logo"
                        src="/logo.svg"
                        width={120 * 2.5}
                        height={24 * 2.5}
                    />
                </Link>

                <Suspense>{children}</Suspense>

                <LanguageSelector
                    className="dropdown-top block lg:hidden [&>div>svg]:text-xl"
                    currentLanguage={lng as 'pt-BR' | 'en'}
                    showCurrentLanguage
                />
            </div>

            <div className="mt-4 flex flex-col gap-2">
                <Separator className="block lg:hidden" />

                <div className="flex justify-between p-4 px-8 lg:hidden">
                    <Link className="underline" href={`/${lng}/info/policy`}>
                        {t('privacy')}
                    </Link>

                    <Link className="underline" href={`/${lng}/support`}>
                        {t('support')}
                    </Link>

                    <Link className="underline" href={`/${lng}/info/terms`}>
                        {t('terms')}
                    </Link>
                </div>

                <div className="hidden justify-between bg-primary p-4 px-8 lg:flex">
                    <p className="text-gray-700">
                        © 2024 - Midas Trackr - All Rights Reserved.
                    </p>

                    <div className="flex gap-4">
                        <Link
                            className="flex items-center gap-1"
                            href="/info/overview"
                        >
                            <MdOutlineQuestionMark /> {t('help_center')}
                        </Link>

                        <Link
                            className="flex items-center gap-1"
                            href={`/${lng}/info/policy`}
                        >
                            <MdLock /> {t('privacy')}
                        </Link>

                        <Link
                            className="flex items-center gap-1"
                            href={`/${lng}/info/terms`}
                        >
                            <MdFileCopy /> {t('terms')}
                        </Link>

                        <Link
                            className="flex items-center gap-1"
                            href={`/${lng}/support`}
                        >
                            <MdSupport /> {t('support')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
