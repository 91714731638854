import { useTranslation } from '@/app/i18n/client';
import { useLng } from '@/hooks/use-lng';
import clsx from 'clsx';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { Button, DialogTrigger } from 'react-aria-components';
import { MdCheck, MdDarkMode, MdLightMode } from 'react-icons/md';
import Dropdown from '../dropdown';
import { DropdownContent } from '../dropdown/dropdown';

interface Props {
    iconClassName?: string;
}

export default function ThemeSwitch({ iconClassName }: Props) {
    const [mounted, setMounted] = useState(false);
    const { theme, resolvedTheme, setTheme } = useTheme();

    const lng = useLng();
    const { t } = useTranslation(lng);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!mounted) {
        return null;
    }

    return (
        <DialogTrigger>
            <Button>
                {resolvedTheme === 'dark' ? (
                    <MdDarkMode className={clsx('text-4xl', iconClassName)} />
                ) : (
                    <MdLightMode className={clsx('text-4xl', iconClassName)} />
                )}
            </Button>
            <Dropdown>
                <DropdownContent>
                    <Button
                        onPress={() => setTheme('system')}
                        className="flex w-full items-center gap-1"
                    >
                        <MdCheck
                            className={theme !== 'system' ? 'opacity-0' : ''}
                        />
                        {t('system')}
                    </Button>
                </DropdownContent>

                <DropdownContent>
                    <Button
                        onPress={() => setTheme('dark')}
                        className="flex w-full items-center gap-1"
                    >
                        <MdCheck
                            className={theme !== 'dark' ? 'opacity-0' : ''}
                        />
                        {t('dark')}
                    </Button>
                </DropdownContent>

                <DropdownContent>
                    <Button
                        onPress={() => setTheme('light')}
                        className="flex w-full items-center gap-1"
                    >
                        <MdCheck
                            className={theme !== 'light' ? 'opacity-0' : ''}
                        />
                        {t('light')}
                    </Button>
                </DropdownContent>
            </Dropdown>
        </DialogTrigger>
        // <select value={theme} onChange={(e) => setTheme(e.target.value)}>
        //     <option value="system">System</option>
        //     <option value="dark">Dark</option>
        //     <option value="light">Light</option>
        // </select>
    );
}
